import * as FullStory from '@fullstory/browser';
import * as SentryBrowser from '@sentry/browser';
import { getValueAndUnit } from 'polished';
import { isEmpty, isString, isNumber, isBoolean, isDate } from 'lodash/fp';
import {
  ILabelValuePair,
  IError,
  ISelectOption,
  IValueOrNull,
  IAddressFieldInfo,
  IRequirement,
  ExperimentVariant,
} from './types';

export const isNextJS = !!process.env.NEXT_PUBLIC_API_HOST;
export const isOnServer = typeof window === 'undefined';

// Retry function to help reduce the frequency of chunk load issues occurring and the instances recorded in Sentry.
export const retry = (fn: any, retriesLeft = 5, interval = 1000) =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

export const getWindowSessionReplayURL = (): string | null => {
  if (!window.bwClient || !window.bwClient.getSessionReplayURL) return null;
  return window.bwClient.getSessionReplayURL();
};

export function captureExceptionWithFullStory(error: IError, extra?: Record<string, any>): void {
  // no FS on server or when headless browser
  if (!isOnServer && !window.isHeadless) {
    SentryBrowser.setTag(
      'FS Session',
      FullStory.isInitialized() ? FullStory.getCurrentSessionURL(true) : 'FS Not Ready',
    );

    SentryBrowser.setTag('BW Session', getWindowSessionReplayURL() ?? 'BW Not Ready');
  }

  if (error.status) {
    SentryBrowser.setTag('error_status', error.status);
  }
  if (error.code) {
    SentryBrowser.setTag('error_code', error.code);
  }
  if (error.requestId) {
    SentryBrowser.setTag('x-request-id', error.requestId);
  }
  if (extra?.url) {
    SentryBrowser.setTag('api_url', extra.url);
  }

  SentryBrowser.captureException(
    error,
    extra && {
      extra,
    },
  );
}

export const valueOrNull: IValueOrNull = (value = null) => value;

export const checkCurrency = (currencies: ISelectOption[], currency: string): string => {
  const formatCurrency = currency.toUpperCase().trim();
  const foundSupportCurrency = currencies.find((currencyList) => currencyList.value === formatCurrency);
  if (currency) {
    if (foundSupportCurrency) {
      return foundSupportCurrency.value!;
    }
  }
  return currencies[0].value!;
};

export const getCurrencySymbolOrName = (locale: string, currency?: string, isSymbol = true): string =>
  currency
    ? (0)
        .toLocaleString(locale, {
          style: 'currency',
          currency,
          currencyDisplay: isSymbol ? 'narrowSymbol' : 'name',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/[0-9\u0660-\u0669]/g, '')
        .trim()
    : '';

export const getCookiesAsObject = (): Record<string, string> | undefined =>
  (!isOnServer &&
    Object.fromEntries(document.cookie.split('; ').map((v) => v.split(/=(.*)/s).map(decodeURIComponent)))) ||
  undefined;

/**
 * Set cookie with expiry days. If there is no expiry days specified (null), cookie will expire on session
 */
export const setCookie = (name: string, value: string, expiryDays: Date | null, path = '/') => {
  if (expiryDays !== null) {
    document.cookie = `${name}=${value};expires=${expiryDays.toUTCString()};path=${path}`;
  } else {
    document.cookie = `${name}=${value};path=${path}`;
  }
};

/**
 * @deprecated use getSessionStorage method in /helpers/storageUtils instead which are more type safe
 */
export const getSessionStorageItem = (itemName: string, parse = true): any | string | null => {
  if (isOnServer) return null;
  const item = sessionStorage.getItem(itemName);

  if (item === null) return null;
  if (!parse) return item;

  return JSON.parse(item);
};

export const setSessionStorageItem = (itemName: string, itemValue: any) =>
  !isOnServer && sessionStorage.setItem(itemName, itemValue);

export const loadCountries = (locale = 'en'): Promise<Record<string, any>> =>
  retry(() => import(`./countries/${locale}`).then((countries) => countries.default)) as Promise<Record<string, any>>;

export const getCookie = (name: string): string | null => {
  if (!document.cookie) {
    return null;
  }

  const cookies = document.cookie
    .split(';')
    .map((c) => c.trim())
    .filter((c) => c.startsWith(`${name}=`));

  if (cookies.length === 0) {
    return null;
  }
  return decodeURIComponent(cookies[0].split('=')[1]);
};

export const multiply = (base = '1rem', multiplier = 1) => {
  switch (multiplier) {
    case 0:
      return 0;

    case 1:
      return base;

    default: {
      const [value, unit] = getValueAndUnit(base);
      return `${value * multiplier}${unit}`;
    }
  }
};

export const deleteCookieByName = (name: string) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export function mergeFunctions(fn1: any, fn2: any) {
  return function mergedFunction(...args: any) {
    // @ts-ignore
    const retSrc = fn1.apply(this, args);
    // @ts-ignore
    const retDst = fn2.apply(this, args);
    return retSrc || retDst;
  };
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
  }
}
export const getReactSelectValue = (value: string, options: ILabelValuePair[]): ILabelValuePair | null =>
  // result cannot be undefined, otherwise react-select will show previous value
  options.filter((option) => option.value === value)?.[0] ?? null;

export const extractCity = (components?: google.maps.GeocoderAddressComponent[], description?: string) => {
  if (!components) return undefined;
  const locality = components.find(({ types }) => types.includes('locality'));
  if (locality) return locality.long_name;
  const postalTown = components.find(({ types }) => types.includes('postal_town'));
  if (postalTown) return postalTown.long_name;
  const neighborhood = components.find(({ types }) => types.includes('neighborhood'));
  if (neighborhood) return neighborhood.long_name;
  const descriptionSplit = description?.split(',');
  return (descriptionSplit && descriptionSplit.length >= 2 && descriptionSplit[1].trim()) || undefined;
};

// remove empty string, undefined, null, false, 0
export const removeFalseValue = (object: Record<string, unknown>): Record<string, unknown> =>
  Object.fromEntries(Object.entries(object).filter(([, value]) => !!value));

export const enumKeys = <T extends Record<string, unknown>, K extends keyof T = keyof T>(object: T): K[] =>
  Object.keys(object).filter((key) => Number.isNaN(+key)) as K[];

export const pickProperties = <T extends Partial<Record<keyof T, unknown>>, P extends string>(
  object: T,
  keys: readonly P[],
) =>
  Object.assign({}, ...keys.map((key) => ({ [key]: object[key as unknown as keyof T] ?? '' }))) as {
    [K in P]: K extends keyof T ? T[K] : '';
  };

// XCMS may return plain text tooltip with special symbol in prod for old content, leading decodeURI error and cause page crash
// ref: https://domainpartners.atlassian.net/browse/XCOM-2715
export const decodedTooltip = (str: string) => {
  try {
    return decodeURIComponent(str);
  } catch (error) {
    return str;
  }
};

export const checkDynamicName = (fieldName: string, nameList?: IAddressFieldInfo<string>) => {
  if (nameList && Object.keys(nameList).includes(fieldName)) {
    return nameList[fieldName as keyof typeof nameList] ?? '';
  }
  return fieldName;
};

/**
 * Check if the value is empty
 * @test utils.spec.ts
 */
export const isEmptyValue = (value: unknown) => {
  if (isString(value)) {
    return value.trim() === '';
  }

  if (isNumber(value) || isBoolean(value) || isDate(value)) {
    return false;
  }

  /**
   * Checks if `value` is an empty object, collection, map, or set.
   *
   * Objects are considered empty if they have no own enumerable string keyed
   * properties.
   *
   * Array-like values such as `arguments` objects, arrays, buffers, strings, or
   * jQuery-like collections are considered empty if they have a `length` of `0`.
   * Similarly, maps and sets are considered empty if they have a `size` of `0`.
   */
  return isEmpty(value);
};

/**
 * Assert a condition which your program assumes to be true.
 *
 * @example
 * 1. Assert that a value is truthy
 *    const example1: string | null | undefined | false | 0 | '' = 'hello';
 *    assert(example1); // ok
 *    typeof example1 === 'string'
 * 2. Cast a unknown (or any) value to a specific type
 *    const example2: unknown = 'hello';
 *    assert(typeof example2 === 'number'); // assertion failed
 *    typeof example2 === 'number' // if assert is true, then example2 is sure to be a number
 * 3. Work with yup validation
 *    const userSchema = object({ name: string().required(), age: number().positive().integer() });
 *    const user: any = { name: 'testUser', age: 24 };
 *    assert(userSchema.isValidSync(user));
 *    typeof user => User
 *
 * @param condition any value or expression that can be coerced to true
 * @param msg error message to be thrown and captured by Sentry
 */
export function assert(condition: unknown, msg = 'Assertion failed'): asserts condition {
  if (!condition) {
    const error = new Error(msg);
    captureExceptionWithFullStory(error);
    throw error;
  }
}

export const getHelpTextID = (fieldName: string) => `${fieldName}HelpTextID`;
export const getDescriptionID = (fieldName: string) => `${fieldName}DescriptionID`;

export function getAriaDescribedBy(
  fieldName: string,
  helpText?: React.ReactNode,
  description?: React.ReactNode,
  requirements?: IRequirement[],
) {
  let ariaDescribedBy = '';
  const requirementIDs = requirements?.reduce(
    (result, requirement) => (result ? `${result} ${requirement.id}` : requirement.id),
    '',
  );
  if (requirementIDs) {
    ariaDescribedBy += `${requirementIDs} `;
  }
  if (helpText) {
    ariaDescribedBy += `${getHelpTextID(fieldName)} `;
  }
  if (description) {
    ariaDescribedBy += `${getDescriptionID(fieldName)} `;
  }
  return ariaDescribedBy.trim();
}
export const onKeyDown =
  (fn: React.ReactEventHandler<HTMLElement> | (() => void)) => (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      fn(event);
    }
  };

export const getABExperimentID = (analyticsID: string, variant: ExperimentVariant) => `${analyticsID}-${variant}`;
