import React, { useEffect, useRef, Suspense } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import * as FullStory from '@fullstory/browser';
import { useBWTag, useScript } from '@whitelabel/helpers/hooks';
import { IUser, ICustomer } from '@whitelabel/helpers/types';
import { ZendeskChatProvider } from '@whitelabel/xcover-shared/components/PartnerZendeskChat';
import { BWChatProvider } from '@whitelabel/xcover-shared/components/BWChat';
import { checkValidHash } from '@whitelabel/helpers/url';
import * as actions from '../../actions';
import { useCookieYesEventListener } from '../../helpers/hooks/cookieYes';
import { authRoutes } from '../../routes/routeHelpers';
import { routes, unauthRoutes, componentsMap } from '../../routes/routes';
import AuthRoute from '../helpers/AuthRoute';
import UnauthRoute from '../helpers/UnauthRoute';
import StyledLoading from '../../styled/StyledLoading';
import ErrorBoundary from '../ErrorBoundary';
import ErrorPage from '../../components/ErrorPage';
import { storeINSInLocalStorage } from '../../helpers/bookings';
import { initializeFullStory } from '../../helpers/utils';

interface IAppProps {
  authenticating: boolean;
  checkSession: (...args: any[]) => any;
  customer?: ICustomer;
  user?: IUser;
}

function App({ checkSession, authenticating, user }: IAppProps) {
  const { pathname, search, hash } = useLocation();

  const pathRef = useRef(pathname);
  const cookieYesScript = window?.location.hostname.startsWith('localhost')
    ? ''
    : 'https://cdn-cookieyes.com/client_data/ad2300bcca186c579bea3c5a/script.js';

  const { locale } = useIntl();

  useEffect(() => {
    const pathLanguage = pathname.split('/')[1]?.toLowerCase();
    if (pathLanguage !== locale) {
      window.location.reload();
    }
  }, [pathname]);

  useScript(cookieYesScript, 'cookieyes');

  useCookieYesEventListener();
  useBWTag();

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  useEffect(() => {
    storeINSInLocalStorage();
    if (pathRef.current !== pathname) {
      window.scroll({ top: 0, left: 0 });
      pathRef.current = pathname;
    }
    if (
      !window.isHeadless &&
      !FullStory.isInitialized() &&
      !pathname.includes('/pds/') &&
      !pathname.includes('/partners/uber-driver')
    ) {
      initializeFullStory();
    }
  }, [pathname]);

  return (
    <BWChatProvider userId={user?.username}>
      {authenticating ? (
        <StyledLoading />
      ) : (
        <ZendeskChatProvider>
          <Suspense fallback={<StyledLoading />}>
            <Switch>
              <Redirect
                from="/(.+)/"
                strict
                exact
                to={{ pathname: pathname.slice(0, -1), search: window.location.search, hash: window.location.hash }}
              />
              {!checkValidHash(hash.slice(1)) && <Redirect to={{ pathname, search }} />}
              {unauthRoutes.map((route) => (
                <UnauthRoute {...route} />
              ))}

              {authRoutes.map((route) => (
                <AuthRoute {...route} component={componentsMap[route.key as keyof typeof componentsMap]} />
              ))}

              {routes.map(({ component: Component, ...restProps }) => (
                <Route
                  {...restProps}
                  render={(routeProps) => (
                    <ErrorBoundary>
                      <Component {...routeProps} />
                    </ErrorBoundary>
                  )}
                />
              ))}
              <Route path="/" exact render={() => <StyledLoading />} />
              <Route render={(routerProps) => <ErrorPage {...routerProps} isNoMatch />} />
            </Switch>
          </Suspense>
        </ZendeskChatProvider>
      )}
    </BWChatProvider>
  );
}

const mapStateToProps = (state: any) => ({
  authenticating: state.user.checkingSession,
  user: state.user.data,
  customer: state.customer.data,
});

const mapDispatchToProps = {
  checkSession: actions.checkSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
