import { Formik, Form, Field } from 'formik';
import React, { useEffect, useCallback, useState } from 'react';
import { debounce } from 'lodash/fp';
import { useIntl } from 'react-intl';
import { object, string } from 'yup';
import * as FullStory from '@fullstory/browser';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { ReactComponent as ExternalLinkIcon } from '@whitelabel/media/icons/small/externallink.svg';
import { bwNPSScoreTracking } from '@whitelabel/helpers/analytics';
import { HeadingTag } from '@whitelabel/helpers/types';
import api from '@whitelabel/helpers/api';
import { getStandardLink } from '@whitelabel/helpers/url';
import {
  EMAIL_FIELD_ID,
  FEEDBACK_FIELD_ID,
  LANGUAGE_FILED_ID,
  NPS_RATING_FIELD_ID,
  BOOKING_REFERENCE_FILED_ID,
  PARTNER_FIELD_ID,
  TOUCH_POINT_FIELD_ID,
} from '@whitelabel/helpers/constants';
import sharedMessages from '@whitelabel/helpers/messages/commonMsg';
import { getWindowSessionReplayURL } from '@whitelabel/helpers/utils';
import StyledDivider from '../../styled/StyledDivider';
import Button from '../Button';
import FormikFormField from '../FormikFormField';
import Alert from '../Alert';
import messages from './messages';
import {
  StyledAdditionalBlock,
  StyledMainWrapper,
  StyledScore,
  StyledScoreList,
  StyledScoreWrapper,
  StyledTitle,
  StyledVerticalDivider,
  StyledPageWrapper,
  StyledLabelWrapper,
} from './styledNPS';

export interface INPSProps {
  className?: string;
  source: string;
  zendeskApiURL: string;
  bookingID?: string;
  headingTag?: HeadingTag;
  customerName?: string;
  customerEmail?: string;
  customerPhone?: string;
  onScoreSelect?: (score: number) => void;
  onFeedbackGiven?: () => void;
  score?: number;
  partnerSlug?: string;
  isModal?: boolean;
  partnerID?: string;
  partnerName?: string;
  subsidiaryID?: string;
  subsidiaryName?: string;
  policyTypeGroupName?: string;
}

const NPS = ({
  className,
  source,
  bookingID,
  headingTag = 'h3',
  onScoreSelect,
  onFeedbackGiven,
  zendeskApiURL,
  customerName,
  customerEmail,
  customerPhone,
  score,
  partnerSlug,
  isModal = false,
  partnerID,
  partnerName,
  subsidiaryName,
  subsidiaryID,
  policyTypeGroupName,
}: INPSProps): JSX.Element => {
  const { locale, formatMessage } = useIntl();
  const [selectedScore, setSelectedScore] = useState<number | undefined>(score);
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const isScoreSelected = selectedScore !== undefined; // selectedScore can be 0 so cannot use native null check
  const theme = useTheme();
  const isMobile = !useMediaQuery({ minWidth: theme.gridBreakpoints.sm });

  const createZendeskTicket = async ({ values, scores }: { values: { feedback: string }; scores?: number }) => {
    if (bookingID || customerEmail || customerPhone) {
      let fullstoryURL = 'n/a';
      if (FullStory.isInitialized()) {
        fullstoryURL = FullStory.getCurrentSessionURL() || 'n/a';
      }

      const requestObj = {
        request: {
          requester: { name: customerName || 'Anonymous', email: customerEmail, locale },
          subject: `Xcover.com - NPS feedback`,
          comment: {
            body: `
            Reference Number: ${bookingID || `N/A`}
            NPS Score: ${scores ?? selectedScore}
            Feedback: ${values.feedback}
            User Email: ${customerEmail || `N/A`}
            Phone Number: ${customerPhone || `N/A`}
            Source: ${source}
            FullStory Session: ${fullstoryURL || 'Not provided'}
            BW Session: ${getWindowSessionReplayURL() || 'Not provided'}
          `,
          },
          custom_fields: [
            { id: LANGUAGE_FILED_ID, value: locale || 'other-lang' },
            { id: BOOKING_REFERENCE_FILED_ID, value: bookingID || '' },
            { id: NPS_RATING_FIELD_ID, value: scores ?? selectedScore },
            { id: PARTNER_FIELD_ID, value: partnerSlug || '' },
            { id: FEEDBACK_FIELD_ID, value: values.feedback },
            { id: EMAIL_FIELD_ID, value: customerEmail || '' },
            { id: TOUCH_POINT_FIELD_ID, value: source || '' },
          ],
        },
      };

      try {
        await api.post(`${zendeskApiURL}/requests`, false, {
          body: JSON.stringify(requestObj),
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (score !== undefined) {
      createZendeskTicket({ values: { feedback: '' } });
    }
  }, [score]);

  const createTicket = (scoreValue: string) =>
    createZendeskTicket({ values: { feedback: '' }, scores: Number(scoreValue) });

  const debouncedCreateTicket = useCallback(debounce(2000, createTicket), []);
  const handleScoreSelect: React.ReactEventHandler<HTMLElement> = (event) => {
    const selectedElement = event.target as HTMLElement;
    const scoreValue = selectedElement.dataset.value as string;
    bwNPSScoreTracking({
      score: scoreValue,
      source,
      bookingID,
      partnerID,
      partnerName,
      subsidiaryName,
      subsidiaryID,
      policyTypeGroupName,
    });
    setSelectedScore(Number(scoreValue));
    debouncedCreateTicket(scoreValue);
    if (onScoreSelect) {
      onScoreSelect(Number(scoreValue));
    }
  };

  const onFeedbackButtonClick = () => {
    setFeedbackGiven(true);
    if (onFeedbackGiven) {
      onFeedbackGiven();
    }
  };

  const renderNPSFeedbackForm = () => {
    const initialValues = { feedback: '' };
    const validationSchema = object({
      feedback: string().trim().required(formatMessage(sharedMessages.feedbackRequireText)).max(2000),
    });

    const handleFeedbackSubmit = (values: typeof initialValues) => {
      bwNPSScoreTracking({
        score: String(selectedScore),
        source,
        bookingID,
        feedback: values.feedback,
        partnerID,
        partnerName,
        subsidiaryName,
        subsidiaryID,
        policyTypeGroupName,
      });
      onFeedbackButtonClick();
      createZendeskTicket({ values });
    };

    return (
      <StyledAdditionalBlock>
        <p>{formatMessage(messages.feedbackLeadText)}</p>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleFeedbackSubmit}>
          <Form>
            <Field
              component={FormikFormField}
              name="feedback"
              type="textarea"
              placeholder={formatMessage(sharedMessages.feedbackPlaceholder)}
            />
            <Button type="submit">{formatMessage(sharedMessages.submitFeedbackText)}</Button>
          </Form>
        </Formik>
      </StyledAdditionalBlock>
    );
  };

  const renderReviewBlock = () => {
    const reviewButtonLink = getStandardLink(formatMessage(sharedMessages.reviewButtonLink));
    return (
      <StyledAdditionalBlock>
        <p>{formatMessage(sharedMessages.reviewLeadText)}</p>
        <Button
          tag="a"
          href={reviewButtonLink}
          rel="noopener noreferrer"
          target="_blank"
          icon={ExternalLinkIcon}
          onClick={onFeedbackButtonClick}
        >
          {formatMessage(sharedMessages.reviewButtonText)}
        </Button>
      </StyledAdditionalBlock>
    );
  };

  const renderMainNPS = () => (
    <>
      <StyledScoreWrapper $scoreSelected={isScoreSelected}>
        <StyledScoreList $isPage={!isModal}>
          {isMobile ? (
            <>
              {[...Array(6)].map((_, index) => {
                const scoreID = `nps-score-${index * 2}`;
                return (
                  <li className="score" key={scoreID}>
                    <StyledScore
                      id={scoreID}
                      data-value={index * 2}
                      onClick={handleScoreSelect}
                      $selected={selectedScore === index * 2}
                      $isPage={!isModal}
                    >
                      {index * 2}
                    </StyledScore>
                  </li>
                );
              })}
              {[...Array(5)].map((_, index) => {
                const scoreID = `nps-score-${index * 2 + 1}`;
                return (
                  <li className="score" key={scoreID}>
                    <StyledScore
                      id={scoreID}
                      data-value={index * 2 + 1}
                      onClick={handleScoreSelect}
                      $selected={selectedScore === index * 2 + 1}
                      $isPage={!isModal}
                    >
                      {index * 2 + 1}
                    </StyledScore>
                  </li>
                );
              })}
            </>
          ) : (
            [...Array(11)].map((_, index) => {
              const scoreID = `nps-score-${index}`;
              return (
                <li className="score" key={scoreID}>
                  <StyledScore
                    id={scoreID}
                    data-value={index}
                    onClick={handleScoreSelect}
                    $selected={selectedScore === index}
                    $isPage={!isModal}
                  >
                    {index}
                  </StyledScore>
                </li>
              );
            })
          )}
        </StyledScoreList>
        <StyledLabelWrapper>
          <p className="negative">{formatMessage(messages.negativeText)}</p>
          <p className="positive">{formatMessage(messages.positiveText)}</p>
        </StyledLabelWrapper>
      </StyledScoreWrapper>
      {isScoreSelected && <StyledDivider $gap="1rem" />}
      {Number(selectedScore) <= 7 && renderNPSFeedbackForm()}
      {Number(selectedScore) > 7 && renderReviewBlock()}
    </>
  );

  return (
    <div className={className}>
      <StyledPageWrapper $isPage={!isModal}>
        <StyledTitle $isPage={!isModal} as={headingTag}>
          {formatMessage(messages.title)}
        </StyledTitle>
        <StyledVerticalDivider />
        <StyledMainWrapper $isPage={!isModal}>
          {feedbackGiven ? (
            <Alert className="feedback" color="success">
              {formatMessage(sharedMessages.feedbackGiven)}
            </Alert>
          ) : (
            renderMainNPS()
          )}
        </StyledMainWrapper>
      </StyledPageWrapper>
    </div>
  );
};

export default NPS;
