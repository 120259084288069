import { useState, useEffect, useRef, RefObject } from 'react';
import { useLocation } from 'react-router-dom';
import ResizeObserver from 'resize-observer-polyfill';
import { FORM_INPUT_TYPES } from './constants';
import { BRIGHTWRITE_APP_ID, BRIGHTWRITE_APP_VERSION, BRIGHTWRITE_PUBLIC_KEY, GOOGLE_PLACES_API_KEY } from './api';
import { IError } from './types';
import { captureExceptionWithFullStory } from './utils';

export const useQueryParams = (): URLSearchParams => new URLSearchParams(useLocation().search);

export function useGoogleAPI(input?: any) {
  const [googleAPILoaded, setGoogleAPILoaded] = useState(!!window.google);

  useEffect(() => {
    const scriptID = 'google-maps-places-api';
    const existingScript = document.getElementById(scriptID);

    if (!existingScript) {
      (window as any).mapsCallback = () => setGoogleAPILoaded(true);
      const script = document.createElement('script');
      script.src =
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}` +
        '&libraries=places&callback=mapsCallback';
      script.id = scriptID;
      if (input !== FORM_INPUT_TYPES.ADDRESS) {
        script.defer = true;
      }
      document.head.appendChild(script);
    }
  }, [input]);
  return googleAPILoaded;
}

export function useClientRect(element: RefObject<HTMLElement>): DOMRectReadOnly {
  const [elementRect, setElementRect] = useState<DOMRectReadOnly>();
  const observer = useRef(
    new ResizeObserver((entries) => {
      setElementRect(entries[0].contentRect);
    }),
  );

  useEffect(() => {
    const elCurrRef = element.current;
    const observerCurrRef = observer.current;
    if (!elCurrRef) return undefined;
    if (elCurrRef) {
      observer.current.observe(elCurrRef);
    }

    return () => {
      if (!elCurrRef) return;
      observerCurrRef.unobserve(elCurrRef);
    };
  }, [element, observer]);

  return elementRect as DOMRectReadOnly;
}

// Ref: https://usehooks.com/useScript/
export function useScript(src: any, id: any, removeScript = false) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle');

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus('idle');
        return undefined;
      }

      // Fetch existing script element by src
      // It may have been added by another instance of this hook
      let script: HTMLScriptElement | undefined = document.querySelector(`script[src="${src}"]`) as
        | HTMLScriptElement
        | undefined;
      // removing the script from the body
      if (script && removeScript) {
        script?.parentElement?.removeChild(script);
      }
      if (!script || (script && removeScript)) {
        // Create script
        script = document.createElement('script');

        script.src = src;
        if (id) {
          script.id = id;
        }
        script.async = true;
        script.setAttribute('data-status', 'loading');
        // Add script to document body
        document.body.appendChild(script);

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event: any) => {
          script!.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
        };

        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status')!);
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event: any) => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
      };

      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);

      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [src], // Only re-run effect if script src changes
  );

  return status;
}
interface IUsePollingArgs<T> {
  query: () => Promise<T>;
  onSuccess?: (result: T) => void;
  onFailure?: (error: unknown) => void;
  getIsFinished: (result: T) => boolean;
  delay: number;
  maxRetries: number;
  startDelay?: number;
}
export function usePolling<T>({
  query,
  onSuccess,
  onFailure,
  getIsFinished,
  delay,
  maxRetries,
  startDelay,
}: IUsePollingArgs<T>) {
  const [data, setData] = useState<T>();
  const [error, setError] = useState<unknown>();
  const [retriesCount, setRetriesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let counter = 0;
    let timeout: any;
    async function poll() {
      setIsLoading(true);
      try {
        const response = await query();
        const isFinished = getIsFinished(response);

        if (isFinished) {
          onSuccess?.(response);
          setData(response);
          setIsLoading(false);
        } else if (!isFinished && counter < maxRetries) {
          counter += 1;
          setRetriesCount(counter);
          timeout = setTimeout(poll, delay);
        } else {
          throw new Error('Maximum retries reached');
        }
      } catch (err) {
        onFailure?.(err);
        setError(err);
        setIsLoading(false);
      }
    }

    if (startDelay) {
      timeout = setTimeout(poll, startDelay);
    } else {
      void poll();
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // Empty deps array to have the code run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, error, retriesCount, isLoading };
}

export const useSessionStorage = (keyName: string, defaultValue: string) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.sessionStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value);
      }
      window.sessionStorage.setItem(keyName, JSON.stringify(defaultValue));
      return defaultValue;
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue: string) => {
    try {
      window.sessionStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      console.error('Error in useSessionStorage while calling setValue function. Error is:', err);
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export const useBWTag = (): void => {
  useEffect(() => {
    if (window.isHeadless) {
      return;
    }

    window.bwtag('set', 'appId', BRIGHTWRITE_APP_ID);
    window.bwtag('set', 'appVersion', BRIGHTWRITE_APP_VERSION);
    window.bwtag('set', 'publicKey', BRIGHTWRITE_PUBLIC_KEY);
    window.bwtag('set', 'autoPvTracking', true);
  }, []);
};

export const useFramerMotion = () => {
  const [framerMotionExports, setFramerMotionExports] = useState<any>({});
  const loadFramerMotion = async () => {
    try {
      const data = await import('framer-motion');
      setFramerMotionExports(data);
    } catch (error) {
      captureExceptionWithFullStory(error as IError);
    }
  };

  useEffect(() => {
    loadFramerMotion();
  }, []);

  return framerMotionExports;
};

export const useDeviceSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{ width: number | undefined; height: number | undefined }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

/**
 * Returns a state value that is delayed by a specified amount of time.
 */
export const useDelayedState = <T>(initialValue: T, delayMs: number): [T, (value: T) => void] => {
  const [value, setValue] = useState<T>(initialValue);
  const [delayedValue, setDelayedValue] = useState<T>(initialValue);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedValue(value);
    }, delayMs);

    return () => clearTimeout(timer);
  }, [value, delayMs]);

  return [delayedValue, setValue];
};
